@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Lao:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Your custom styles go here */

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

/* .trigger:hover {
  color: #1890ff;
} */

.logo {
  height: 32px;
  margin: 16px;
  /* background: rgba(255, 255, 255, 0.3); */
}

.selected-menu-item {
  border-bottom: none;
}


.ant-layout {
  min-height: 100vh;
  background-color: #f1f5f9;
}

.ant-layout-sider {
  flex: 0 0 250px !important;
  max-width: 250px !important;
  min-width: 250px !important;
  width: 250px !important;
}

.ant-layout-sider-collapsed {
  flex: 0 0 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
  width: 80px !important;
}

.sm-logo {
  display: none;
}

.ant-layout-sider-collapsed .sm-logo {
  display: block;
}

.ant-layout-sider-collapsed .lg-logo {
  display: none;
}

.logo {
  margin: 0;
  height: 64px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* color: #FFFFFF; */
}

main {
  height: 5vh;
  overflow-y: hidden;
  background-color: #f1f5f9 !important;
}

main::-webkit-scrollbar {
  width: 0px;
}

.ant-menu-title-content {
  font-size: 16px;
}

.ant-layout-header h5 {
  line-height: 20px;
  font-size: 14px;
}

.ant-layout-header p {
  line-height: 20px;
  font-size: 13px;
}

.ant-layout-header div .badge {
  top: 16px;
  right: -6px;
}

.error {
  color: red;
  font-size: 12px;
}